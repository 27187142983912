import {
  shallowRef,
  onUnmounted,
} from 'vue';


export function useSecondsTimer(
  milliseconds: number,
  afterEnd: Function,
  stopOnUnmount?: boolean,
) {
  const timer = shallowRef(Math.round(milliseconds / 1000));
  const startTime = new Date();

  const intervalId = setInterval(() => {
    const currentTime = new Date();
    const timeDifferent = currentTime.valueOf() - startTime.valueOf();
    timer.value = Math.round((milliseconds - timeDifferent) / 1000);

    if (timeDifferent > milliseconds) {
      stopTimer();
      afterEnd();
    }
  }, 200);
  function stopTimer() {
    clearInterval(intervalId);
  }

  if (stopOnUnmount) {
    onUnmounted(stopTimer);
  }

  return {
    timer,
    stopTimer,
  };
}
