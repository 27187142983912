import type { FunctionalComponent } from 'vue';
import { QIcon } from 'quasar';

// eslint-disable-next-line max-len
import starBackgroundJpg from '@/components/icons/Star/star-background-32x23.jpg';


export const StarSVGDefs = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="absolute"
    width="0"
    height="0"
  >
    <defs>
      <pattern
        id="star-background-pattern"
        patternContentUnits="userSpaceOnUse"
        width="1"
        height="1"
      >
        <image
          id="star-background"
          xlinkHref={starBackgroundJpg}
        />
      </pattern>

      {/* <mask id="star">
        <path
          d="M7.89358 12.9372L3.01916 15.5L3.95029 10.0719L0.00146484 6.22816L5.45084 5.4384L7.88805 0.5L10.3253 5.4384L15.7746 6.22816L11.8258 10.0719L12.757 15.5L7.89358 12.9372Z"
          style="fill:white;"
        />
      </mask> */}
    </defs>
  </svg >
);

export const StarSVG: FunctionalComponent = () => {
  return (
    <QIcon size="16px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.89358 12.9372L3.01916 15.5L3.95029 10.0719L0.00146484 6.22816L5.45084 5.4384L7.88805 0.5L10.3253 5.4384L15.7746 6.22816L11.8258 10.0719L12.757 15.5L7.89358 12.9372Z"
          fill="url(#star-background-pattern)"
        />
        {/* <image
          x="0"
          y="0"
          width="32"
          height="23"
          xlinkHref={starBackgroundJpg}
          mask="url(#star)"
        /> */}
      </svg>
    </QIcon>
  );
};
