import { returnFalse } from '@/utils/returns';

export const earray = Object.freeze([] as unknown[]);

export const eobject = Object.freeze({});

export const efunction = Object.freeze(function efunction() {});

export const eSignal = Object.freeze({
  aborted: false,
  onabort: efunction,
  reason: undefined,
  throwIfAborted: efunction,
  addEventListener: efunction,
  removeEventListener: efunction,
  dispatchEvent: returnFalse,
});

export const eAbortController = Object.freeze({
  abort: efunction,
  signal: eSignal,
} as AbortController);

export const emap = Object.freeze(new Map());
export const eset = Object.freeze(new Set());
