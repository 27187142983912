import type { AxiosRequestConfig } from 'axios';

import { http } from '@/plugins/axios';
import type { Offer } from '@/api/chatbotMessages';
import type { City } from '@/api/setupsCities';
import type { PVZ } from '@/api/ordersPVZ';
import type { OrderStatus } from '@/constants';
import type { ShortDatetime } from '@/commonTypes';


export interface OrdersCartItem {
  id: number,
  count: number,
  price: number,
  total_price: number,
  offer: Offer,
  count_for_return: number,
  is_active: boolean,
  cashback: string,
  status:string,
  total_cashback: string,
  rating_data: {
    avg_rating: number,
    reviews_count: number,
  },
}
export interface ReturnOrdersCartItem {
  id: number,
  count: number,
  fix_price: number,
  fix_total_price: number,
  offer: Offer,
  status:string,
  order: Order['id'],
}
export type OrdersCartGetResult = {
  active_count: number,
  active_price: number,
  all_count: number,
  all_price: number,
  order_items: OrdersCartItem[],
  cashback_for_pay: number,
};
export function ordersCartGet(
  config?: AxiosRequestConfig,
) {
  return http.get<OrdersCartGetResult>('/orders/cart/', config);
}

export type OrdersCartPostData = {
  count: number,
  offer: Offer['uuid'],
};
export function ordersCartPost(
  data: OrdersCartPostData,
  config?: AxiosRequestConfig,
) {
  return http.post<OrdersCartItem>(
    '/orders/cart/',
    data,
    config,
  );
}

export function ordersCartCleanCartGet(
  config?: AxiosRequestConfig,
) {
  return http.get('/orders/cart/clean_cart/', config);
}

export function ordersCartDeleteById(
  id: OrdersCartItem['id'],
  config?: AxiosRequestConfig,
) {
  return http.delete(`/orders/cart/${id}/`, config);
}


export enum DeliveryType {
  PVZ = 1,
  COURIER = 2,
}
type OrdersCartCreateOrderPostData<DT extends DeliveryType> = (
  {
    delivery_type: DT,
    city: City['id'],
    cashback_for_pay?: OrdersCartGetResult['cashback_for_pay'],
  }
  & (
    DT extends DeliveryType.PVZ
      ? { pvz: PVZ['id'] }
      : {
        street: string,
        house: string,
        flat: string,
        entrance?: string,
        floor?: string,
        comment?: string,
      }
  )
);

export type Order<DT extends DeliveryType = DeliveryType> = (
  {
    id: number,
    delivery_type: DeliveryType,
    city: City,
    total_price: string,
    created_at: ShortDatetime,
    status: OrderStatus,
    order_items: OrdersCartItem[],
    return_items: ReturnOrdersCartItem[],
    total_cashback: string,
    cashback_for_pay?: number,
  }
  & (
    DT extends DeliveryType.PVZ
      ? {
        pvz: PVZ,

        street: '',
        house: '',
        flat: '',
        entrance: '',
        floor: '',
        comments: '',
      }
      : {
        pvz: null,

        street: string,
        house: string,
        flat: string,
        entrance: string,
        floor: string,
        comment: string,
      }
  )
);
export type OrdersCartCreateOrderPostResult = Order;

export function ordersCartCreateOrderPost<DT extends DeliveryType>(
  data: OrdersCartCreateOrderPostData<DT>,
  config?: AxiosRequestConfig<OrdersCartCreateOrderPostData<DT>>,
) {
  return http.post<OrdersCartCreateOrderPostResult>(
    '/orders/cart/create_order/',
    data,
    config,
  );
}
