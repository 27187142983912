import { shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { EventBus } from 'quasar';

import type { Props as MessageActionProps }
  from '@/views/Main/BotChat/MessageAction';
import type { ChatMessage } from '@/api/chatbotMessages';
import type { Props as ReviewsDialogProps }
  from '@/views/Main/BotChat/ReviewsDialog/index.vue';
import type { Attachment } from '@/api/attachmentsUploadAttachment';
import type { Props as UnitsDialogProps }
  from '@/views/Main/BotChat/UnitsDialog/index.vue';
import type { Props as OfferDialogProps }
  from '@/views/Main/BotChat/OfferDialog/index.vue';
import type { Props as OffersSortingDialogProps }
  from '@/views/Main/BotChat/OffersSortingDialog.vue';
import type { Props as OffersFiltersDialogProps }
  from '@/views/Main/BotChat/OffersFiltersDialog.vue';


export type BotChatEvents = {
  // TODO: возможно вместо "processMessage" использовать набор событий
  processMessage: MessageActionProps['processMessage'],
  clickSmiley(): void,
  [scrollTo: `scrollTo-${ChatMessage['id']}`]: () => void,
};

const botChatEmitter = new EventBus<BotChatEvents>();

export const useBotChatStore = defineStore('botChat', () => {
  const loading = shallowRef(false);
  function setLoadingTrue() {
    loading.value = true;
  }
  const toEntryPointLoading = shallowRef(false);
  

  const reviewsDialogExists = shallowRef(false);
  const reviewsDialogProps = shallowRef<ReviewsDialogProps>();
  function openReviewsDialog(props: ReviewsDialogProps) {
    reviewsDialogExists.value = true;
    reviewsDialogProps.value = props;
  }
  function onHideReviewsDialog() {
    reviewsDialogProps.value = undefined;
  }

  const imageDialogExists = shallowRef(false);
  const imageDialogImage = shallowRef<Attachment['file']>();
  function openImageDialog(image: Attachment) {
    imageDialogExists.value = true;
    imageDialogImage.value = image.file;
  }
  function onHideImageDialog() {
    imageDialogImage.value = undefined;
  }
  
  const unitsDialogExists = shallowRef(false);
  const unitsDialogProps = shallowRef<UnitsDialogProps>();
  function openUnitsDialog(props: UnitsDialogProps) {
    unitsDialogExists.value = true;
    unitsDialogProps.value = props;
  }
  function onHideUnitsDialog() {
    unitsDialogProps.value = undefined;
  }

  const offerDialogExists = shallowRef(false);
  const offerDialogProps = shallowRef<OfferDialogProps>();
  function openOfferDialog(props: OfferDialogProps) {
    offerDialogExists.value = true;
    offerDialogProps.value = props;
  }
  function onHideOfferDialog() {
    offerDialogProps.value = undefined;
  }

  const offersSortingDialogExists = shallowRef(false);
  const offersSortingDialogProps = shallowRef<OffersSortingDialogProps>();
  function openOffersSortingDialog(props: OffersSortingDialogProps) {
    offersSortingDialogExists.value = true;
    offersSortingDialogProps.value = props;
  }
  function onHideOffersSortingDialog() {
    offersSortingDialogProps.value = undefined;
  }

  const offersFiltersDialogExists = shallowRef(false);
  const offersFiltersDialogProps = shallowRef<OffersFiltersDialogProps>();
  function openOffersFiltersDialog(props: OffersFiltersDialogProps) {
    offersFiltersDialogExists.value = true;
    offersFiltersDialogProps.value = props;
  }
  function onHideOffersFiltersDialog() {
    offersFiltersDialogProps.value = undefined;
  }

  
  function onLogout() {
    loading.value = false;
    toEntryPointLoading.value = false;

    reviewsDialogProps.value = undefined;
    imageDialogImage.value = undefined;
    unitsDialogProps.value = undefined;
    offerDialogProps.value = undefined;
    offersSortingDialogProps.value = undefined;
    offersFiltersDialogProps.value = undefined;

    reviewsDialogExists.value = false;
    imageDialogExists.value = false;
    unitsDialogExists.value = false;
    offerDialogExists.value = false;
    offersSortingDialogExists.value = false;
    offersFiltersDialogExists.value = false;
  }

  return {
    botChatEmitter,

    loading,
    setLoadingTrue,
    toEntryPointLoading,

    reviewsDialogExists,
    reviewsDialogProps,
    openReviewsDialog,
    onHideReviewsDialog,

    imageDialogExists,
    imageDialogImage,
    openImageDialog,
    onHideImageDialog,

    unitsDialogExists,
    unitsDialogProps,
    openUnitsDialog,
    onHideUnitsDialog,

    offerDialogExists,
    offerDialogProps,
    openOfferDialog,
    onHideOfferDialog,

    offersSortingDialogExists,
    offersSortingDialogProps,
    openOffersSortingDialog,
    onHideOffersSortingDialog,
    
    offersFiltersDialogExists,
    offersFiltersDialogProps,
    openOffersFiltersDialog,
    onHideOffersFiltersDialog,

    onLogout,
  };
});
