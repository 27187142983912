export const computed$ = Symbol('computed');

export enum ViewName {
  Login = 'Login',
  Main = 'Main',
  Promo = 'Promo'
}

export const enum BotMessageType {
  TEXT = 'TEXT',
  KEYBOARD = 'KEYBOARD',
  PRODUCTS_LIST = 'PRODUCTS_LIST',
  OFFERS = 'OFFERS',
  VEHICLES_LIST = 'VEHICLES_LIST',
  ADD_AUTO_WIZARD = 'ADD_AUTO_WIZARD',
  GOODS_LIST = 'GOODS_LIST',
}
export const enum UserMessageType {
  TEXT = 'TEXT',
  BUTTON_PRESSED = 'BUTTON_PRESSED',
  COMMAND = 'COMMAND',
  PAYLOAD = 'PAYLOAD',
}

export const BACKEND_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ';
export const BACKEND_SHORT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mmZ';
export const FRONTEND_DATETIME_FORMAT = 'DD.MM.YY HH:mm:ss';
export const FRONTEND_DATETIME_WITHOUT_SECONDS_FORMAT = 'DD.MM.YY HH:mm';
export const FRONTEND_TIME_FORMAT = 'HH:mm';

export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_DAY = ONE_HOUR * 24;
// export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;

export const RUBLE = '₽';
export const DASH = '–';

export enum PaymentResult {
  payment_success = 'payment_success',
  payment_failed = 'payment_failed',
}

export enum OrderStatus {
  'NEW' = 'NEW',
  'CANCELED' = 'CANCELED',
  'AWAITING_PAYMENT' = 'AWAITING_PAYMENT',
  'PAYED' = 'PAYED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'IN_DELIVERY' = 'IN_DELIVERY',
  'WAIT_IN_PICKUP_POINT' = 'WAIT_IN_PICKUP_POINT',
  'DELIVERED' = 'DELIVERED',
  'RETURN_REQUEST' = 'RETURN_REQUEST',
  'RETURN_REJECTED' = 'RETURN_REJECTED',
  'RETURN_APPROVED' = 'RETURN_APPROVED',
  'CANCEL_REQUEST' = 'CANCEL_REQUEST',
  'REFUND_DONE' = 'REFUND_DONE',
}

export enum UserMessagePayloadAction {
  'product_selected' = 'product_selected',
  'vehicle_selected' = 'vehicle_selected',
  'auto_from_wizard_selected' = 'auto_from_wizard_selected',
  'get_offers_by_order' = 'get_offers_by_order',
  'get_offers_by_code' = 'get_offers_by_code',
}
