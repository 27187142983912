import { createApp } from 'vue';
import type { HTMLAttributes } from 'vue';
import { createPinia } from 'pinia';
import {
  Quasar,
  Notify,
  Loading,
  Ripple,
  Dialog,
} from 'quasar';
import 'quasar/src/css/index.sass';
import quasarLang from 'quasar/lang/ru';
import '@quasar/extras/material-icons/material-icons.css';

import App from '@/App.vue';
import { router } from '@/router';
import '@/styles/index.scss';
import { useCartStore } from '@/stores/cartStore';
import { useAuthStore } from '@/stores/authStore';
import { http } from '@/plugins/axios';


const app = createApp(App);

// TODO: убрать, когда "quasar" исправит типы для JSX
declare module 'quasar' {
  interface QSkeletonProps extends HTMLAttributes {}
  interface QLayoutProps extends HTMLAttributes {}
  interface QPageContainerProps extends HTMLAttributes {}
  interface QBtnProps extends HTMLAttributes {}
}
app.use(
  Quasar,
  {
    lang: quasarLang,
    plugins: {
      Notify,
      Loading,
      Dialog,
    },
    directives: { Ripple },
    config: {
      // notify: {},
      // loading: {},
      brand: { primary: 'var(--gradient-color-2)' },
      // loading: {...}, // default set of options for Loading Quasar plugin
      // loadingBar: { ... }, // settings for LoadingBar Quasar plugin
      // (check Installation card on each Quasar component/directive/plugin)
      ripple: { color: 'primary' },
      // animations: ['fadeInLeft', 'fadeOutRight'],
    },
  },
);
app.use(createPinia());
app.use(router);


declare global {
  interface Window {
    isFlutterInAppWebViewReady: boolean,
    flutter_inappwebview?: {
      callHandler?: (handlerName: string, ...args: any[]) => Promise<any>;
    }
  }
}
app.runWithContext(() => {
  const authStore = useAuthStore();
  if (authStore.isAuthorized && authStore.accessToken) {
    http.setAuthorization(authStore.accessToken);
  }

  useCartStore().sendToFlutterCartProcessDataSize();
});

app.mount('#app');
