import { watch } from 'vue';
import {
  useRouter,
  START_LOCATION,
} from 'vue-router';
import type { Router } from 'vue-router';


type DialogNameInQuery = string;
type GetDialogVisible = () => boolean;
type SetDialogVisible = (visible: boolean) => void;
type DialogData = [
  getter: GetDialogVisible,
  setter: SetDialogVisible,
  // Нужно для проверки нажатия кнопки назад
  isPopState: boolean,
];
const dialogs = new Map<DialogNameInQuery, DialogData>();

export const handleRouteChange: Parameters<Router['afterEach']>['0'] = (
  to,
  from,
) => {

  if (from === START_LOCATION) {
    return;
  }
  
  for (const [queryName, dialogData] of dialogs) {
    const hasQueryNameInTo = queryName in to.query;
    /*
      Если изменились query между состояния страницами
      и новое состояние не равно старому
    */
    if (
      hasQueryNameInTo !== queryName in from.query
      && hasQueryNameInTo !== dialogData[0]()
    ) {
      dialogData[2] = true;
      dialogData[1](hasQueryNameInTo);
    }
  }
};

/*
  Необходимо указать в QDialog параметр "no-route-dismiss".
  В диалоговых окнах не использовать query-параметры,
  кроме параметров диалогов
*/
export function useDialogInQuery(
  getVisible: () => boolean,
  setVisible: (visible: boolean) => void,
  queryName: string,
  // checkOnOpenImmediate?: boolean,
) {
  const dialogData: DialogData = [
    getVisible,
    setVisible,
    getVisible(),
  ];
  dialogs.set(queryName, dialogData);
  const router = useRouter();

  return watch(
    getVisible,
    (visible) => {
      /*
        При открытии добавляется адрес с хэшем.
        При закрытии возврат назад.
      */
      if (visible) {
        router.push({
          query: {
            ...router.currentRoute.value.query,
            [queryName]: '', 
          },
        });
      } else if (!dialogData[2]) {
        router.back();
      }/*  else {
        const query = { ...router.currentRoute.value.query };
        delete query[queryName];
        router.replace({ query });
      } */
      dialogData[2] = false;
    },
    (
      dialogData[2]
        ? { immediate: dialogData[2] }
        : undefined
    ),
  );
}
