import { shallowRef } from 'vue';
import type {
  ShallowRef,
  ShallowReactive,
} from 'vue';
import { debounce } from 'quasar';

import {
  ordersCartPost,
  ordersCartDeleteById,
} from '@/api/ordersCart';
import type {
  OrdersCartGetResult,
  OrdersCartItem,
} from '@/api/ordersCart';
import type { LikeRef } from '@/commonTypes';
import { http } from '@/plugins/axios';


export type CartItemProcessData = {
  count: ShallowRef<OrdersCartItem['count']>,
  offer: ShallowRef<OrdersCartItem['offer']>,
  updateCount(newLastOnServerCount: OrdersCartItem['count']): void,
  cancelUpdateRequest(): void,

  deleting: ShallowRef<boolean>,
  delete(): void,
  cancelDeleteRequest(): void,

  sync(cartItem: OrdersCartItem): void,
};

export type CartItemProcessContext = {
  getCart: () => void,
  setCartLoadingTrue: () => void,
  getCartAbortController: LikeRef<AbortController>,
  cartProcessData: ShallowReactive<Map<
    CartItemProcessData['offer']['value']['uuid'],
    CartItemProcessData
  >>,
  /*
    TODO: при добавлении можно поймать момент,
    когда можно нажать на кнопку до появления загрузки
    и добавления товара в корзину сервера.
    Нужно завести контекстную переменную,
    где будут хранится все несинхронизованные предложения.
    И если в этой переменной будут элементы, то кнопку блокировать.
  */
  //notSynchronized: ShallowReactive<Set<CartItemProcessData['offer']['uuid']>>;
};

export function createCartItemProcessData(
  cartItem: Readonly<Pick<
    OrdersCartGetResult['order_items'][number],
    'count' | 'offer'
  >>,
  context: Readonly<CartItemProcessContext>,
  // Используется и как локальная переменная
  serverId?: OrdersCartGetResult['order_items'][number]['id'],
): CartItemProcessData {

  let updateCountAbortController = http.eAbortController;
  // Нужен на случай очистки корзины
  let deleteAbortController = http.eAbortController;
  // Последнее количество, которое было на сервере
  let lastOnServerCount = serverId ? cartItem.count : NaN;

  // Удаление товара из корзины сервера
  function deleteFromServer() {
    if (!serverId || result.deleting.value) {
      return;
    }
    // Отменить запрос на добавление
    updateCountAbortController.abort();
    deleteAbortController = new AbortController();
    result.deleting.value = true;
    context.setCartLoadingTrue();
    ordersCartDeleteById(
      serverId,
      { signal: deleteAbortController.signal },
    )
      .then((response) => {
        if (response.config.signal !== deleteAbortController.signal) {
          return;
        }
        context.cartProcessData.delete(result.offer.value.uuid);
        context.getCart();
      })
      .catch((error) => {
        if (http.isCancel(error)) {
          return;
        }
        http.showErrorMessage(error, 'Не удалось удалить товар из корзины');
      })
      .finally(() => {
        result.deleting.value = false;
      });
  }

  const result: ReturnType<typeof createCartItemProcessData> = {
    count: shallowRef(cartItem.count),
    offer: shallowRef(cartItem.offer),

    updateCount(count) {
      // Нельзя обновлять количество во время удаления
      if (result.deleting.value) {
        return;
      }
      // Отменить запросы на получение и добавление
      context.getCartAbortController.value.abort();
      result.cancelUpdateRequest();
      // Изменить количество и послать запрос на синхронизацию
      result.count.value = count;
      context.setCartLoadingTrue();
      _ordersCartPost(count);
    },
    cancelUpdateRequest() {
      updateCountAbortController.abort();
      _ordersCartPost.cancel();
      updateCountAbortController = http.eAbortController;
    },

    deleting: shallowRef(false),
    /*
      Удаление с сервера, если товар уже добавлен на сервер,
      или удаление из интерфейса
    */
    delete: serverId
      ? deleteFromServer
      : () => {
        result.cancelUpdateRequest();
        // Ждём завершения возможных Promise'ов запросов на добавление
        queueMicrotask(() => {
          /*
            Если товар успел добавиться на сервер,
            но корзина ещё не обновилась
          */
          if (serverId) {
            deleteFromServer();
          } else {
            context.cartProcessData.delete(result.offer.value.uuid);
          }
        });
      },
    cancelDeleteRequest() {
      deleteAbortController.abort();
      deleteAbortController = http.eAbortController;
    },

    // Синхронизация с сервером
    sync(cartItem) {
      serverId = cartItem.id;
      lastOnServerCount = cartItem.count;
      result.offer.value = cartItem.offer;
      /*
        TODO: при синхронизации также проверять in_stock_quantity,
        потому что у старых предлоежние количество может быть больше.
        В этом случае снижаем количество до предельного.
        Возможно в этом случае придётся отказать от проверки
        на совпадение предыдущему числу товаров с отменой запросов из-за этого
      */
    },
  };

  // Отложенное обновление на сервере
  const _ordersCartPost = debounce(
    (newLastOnServerCount: typeof lastOnServerCount) => {
      if (lastOnServerCount === result.count.value) {
        return;
      }
      updateCountAbortController = new AbortController();
      context.setCartLoadingTrue();
      ordersCartPost(
        {
          offer: result.offer.value.uuid,
          count: result.count.value,
        },
        { signal: updateCountAbortController.signal },
      )
        .then((response) => {
          serverId = response.data.id;
          result.delete = deleteFromServer;
          lastOnServerCount = newLastOnServerCount;
          return response;
        })
        .catch((error) => {
          if (http.isCancel(error)) {
            return;
          }
          // Если товар на сервере, то сбросить количество, иначе удалить
          if (serverId) {
            result.count.value = lastOnServerCount;
          } else {
            result.delete();
          }
          http.showErrorMessage(error, 'Не удалось добавить товар в корзину');
          if (http.isAxiosError(error)) {
            return error.response;
          }
        })
        // Обновление корзины в любом случае
        .then((response) => {
          if (!response) {
            return;
          }

          if (response.config.signal === updateCountAbortController.signal) {
            context.getCart();
          }
        });
    },
    500,
  );

  /*
    Если товар не на сервере (только что добавлен),
    то послать запрос на добавление.
    В изначальном количестве указано "NaN", поэтому запрос пройдёт
  */
  if (!serverId) {
    _ordersCartPost(cartItem.count);
  }

  return result;
}
