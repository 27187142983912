<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  shallowRef,
  watch,
  provide,
} from 'vue';
import type { InjectionKey } from 'vue';
import { RouterView } from 'vue-router';

import { SVGGradient } from '@/components/icons/common';
import { StarSVGDefs } from '@/components/icons/Star/index';
import { PaymentResult } from '@/constants';
import { useDialogInQuery } from '@/plugins/useDialogInQuery';
import { router } from '@/router';
import { eobject } from '@/utils/empties';
import type { Props as AcceptDialogProps } from '@/components/AcceptDialog.vue';
import AcceptDialog from '@/components/AcceptDialog.vue';
import { useOneTimeRef } from '@/utils/useOneTimeRef';


export type OpenAcceptDialog = (props: AcceptDialogProps) => void;
export const openAcceptDialogInjectionKey: InjectionKey<OpenAcceptDialog>
  = Symbol('openAcceptDialog');

export default defineComponent({
  name: 'App',
  components: {
    RouterView,

    PaymentResultDialog: defineAsyncComponent(
      () => import('@/components/PaymentResultDialog.vue'),
    ),
    AcceptDialog,
    // AcceptDialog: defineAsyncComponent(
    //   () => import('@/components/AcceptDialog.vue'),
    // ),
  },
  setup() {
    const paymentResultDialogVisible = shallowRef(false);
    const paymentResult = shallowRef(PaymentResult.payment_success);
    const unwatchPaymentDialogVisible = useDialogInQuery(
      () => paymentResultDialogVisible.value,
      (value) => { paymentResultDialogVisible.value = value; },
      '_d_payment',
    );

    // Ждём выставления первой страницы
    const unwatch = watch(
      () => Boolean(router.currentRoute.value.name),
      (hasRoute) => {
        if (!hasRoute) {
          return;
        }

        unwatch();
        const query = { ...router.currentRoute.value.query };
        const setVisibleTrue = (
          query.action === PaymentResult.payment_success
          || query.action === PaymentResult.payment_failed
        );
        if (setVisibleTrue) {
          paymentResult.value = query.action as PaymentResult;
        } else {
          unwatchPaymentDialogVisible();
        }

        const routerReplacePromise = router.replace({ query: eobject });
        if (setVisibleTrue) {
          routerReplacePromise
            .then(() => {
              /*
                При открытии Диалога происходит изменение адреса
                и они не должны мешать друг другу
              */
              paymentResultDialogVisible.value = true;
            });
        }
      },
    );


    const acceptDialogExists = useOneTimeRef(false);
    const acceptDialogProps = shallowRef<AcceptDialogProps>();
    provide(
      openAcceptDialogInjectionKey,
      (props) => {
        acceptDialogProps.value = props;
        acceptDialogExists.set(true);
      },
    );
    function onHideAcceptDialog() {
      acceptDialogProps.value = undefined;
    }

    return {
      SVGGradient,
      StarSVGDefs,

      PaymentResult,
      paymentResult,
      paymentResultDialogVisible,

      acceptDialogExists,
      acceptDialogProps,
      onHideAcceptDialog,
    };
  },
});
</script>

<template>
<component :is="SVGGradient" />
<component :is="StarSVGDefs" />

<!-- TODO: Диалог закрывается без анимации, сделать v-if отложенным -->
<PaymentResultDialog
  v-if="paymentResultDialogVisible"
  :result="paymentResult"
  v-model:visible="paymentResultDialogVisible"
/>
<AcceptDialog
  v-if="acceptDialogExists.value"
  :on-hide="onHideAcceptDialog"
  v-bind="acceptDialogProps"
/>

<RouterView>
  <template #default="{ Component }">
    <Transition
      name="fade"
      mode="out-in"
    >
      <component
        :is="Component"
        class="max-w-100vw"
      />
    </Transition>
  </template>
</RouterView>
</template>

<style>
/* Переопределения */
body {
  font-size: 16px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  color: var(--text-color);
}
.q-btn {
  text-transform: none;
  font-weight: 400;
  color: var(--gradient-color-2);
}
.q-chip > .q-icon {
  display: none;
}

.q-field.textarea-no-resize textarea {
  resize: none;
}
.q-field.full-height-textarea > .q-field__inner > .q-field__control {
  height: 100%;
}

.q-slider-center-first-last.q-slider
> .q-slider__marker-labels-container
> .q-slider__marker-labels:first-child {
  transform: translate(0);
}
.q-slider-center-first-last.q-slider
> .q-slider__marker-labels-container
> .q-slider__marker-labels:last-child {
  transform: translate(-100%);
}

.disabled-flat-button[disabled] {
  opacity: 1 !important;
  color: var(--disabled-color) !important;
}

.scroll-area-x-padding.q-scrollarea
> .q-scrollarea__container
> .q-scrollarea__content {
  padding-right: 14px;
  padding-left: 5px;
}

i.stroke-width-1 > svg {
  stroke-width: 1;
}

/* Простые классы-свойства */
.h-100vh {
  height: 100vh;
}
.h-unset {
  height: unset;
}
.min-h-100vh {
  min-height: 100vh;
}

.max-w-100vw {
  max-width: 100vw;
}
.max-w-100 {
  max-width: 100%;
}
.w-50 {
  width: 50%;
}
.w-100px {
  width: 100px;
}
.w-600px {
  width: 600px;
}

.pt-4px {
  padding-top: 4px;
}
.pt-10px {
  padding-top: 10px;
}
.pt-14px {
  padding-top: 14px;
}
.pt-20px {
  padding-top: 20px;
}

/* .pr-0 {
  padding-right: 0;
} */
.pr-5px {
  padding-right: 5px;
}
.pr-10px {
  padding-right: 10px;
}
.pr-14px {
  padding-right: 14px;
}
.pr-18px {
  padding-right: 18px;
}
.pr-46px {
  padding-right: 46px;
}
.pl-5px {
  padding-left: 5px;
}
.pl-10px {
  padding-left: 10px;
}
.pl-46px {
  padding-left: 46px;
}

.pb-10px {
  padding-bottom: 10px;
}
.pb-14px {
  padding-bottom: 14px;
}
.pb-20px {
  padding-bottom: 20px;
}
.pb-24px {
  padding-bottom: 24px;
}
.pb-30px {
  padding-bottom: 30px;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-14px {
  padding-top: 14px;
  padding-bottom: 14px;
}
.py-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-14px {
  padding-left: 14px;
  padding-right: 14px;
}
.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.pa-0 {
  padding: 0;
}
.pa-14px {
  padding: 14px !important;
}
.pa-20px {
  padding: 20px;
}
.pa-28px {
  padding: 28px;
}

.mb-auto {
  margin-bottom: auto;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-6px {
  margin-bottom: 6px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-12px {
  margin-bottom: 12px;
}
.mb-10px-not-last:not(:last-child) {
  margin-bottom: 10px;
}
.mb-18px {
  margin-bottom: 18px;
}
.mb-18px-not-first:not(:first-child) {
  margin-bottom: 18px;
}
.mb-18px-not-last:not(:last-child) {
  margin-bottom: 18px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-20px-not-last:not(:last-child) {
  margin-bottom: 20px;
}
.mb-24px {
  margin-bottom: 24px;
}

.mt-0 {
  margin-top: 0;
}
.mt-auto {
  margin-top: auto;
}
.mt-4px {
  margin-top: 4px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-14px {
  margin-top: 14px;
}
.mt-18px {
  margin-top: 18px;
}
.mt-20px {
  margin-top: 20px;
}

.mr-auto {
  margin-right: auto;
}
.mr-4px {
  margin-right: 4px;
}
.mr-6px {
  margin-right: 6px;
}
.mr-8px {
  margin-right: 8px;
}
.mr-12px {
  margin-right: 12px;
}
.mr-14px {
  margin-right: 14px;
}
.mr-28px {
  margin-right: 28px;
}

.ml-auto {
  margin-left: auto;
}
.ml-n8px {
  margin-left: -8px;
}
.ml-10px {
  margin-left: 10px;
}
.ml-12px {
  margin-left: 12px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.font-size-20px {
  font-size: 20px !important;
}
.font-size-18px {
  font-size: 18px !important;
}
.font-size-16px {
  font-size: 16px;
}
.font-size-16px-children * {
  font-size: 16px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-12px {
  font-size: 12px !important;
}

.line-height-18px {
  line-height: 18px;
}

.d-inline-flex {
  display: inline-flex;
}
.d-table {
  display: table;
}
.d-table-row {
  display: table-row;
}
.d-table-cell {
  display: table-cell;
}
.d-none {
  display: none !important;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-1 {
  opacity: 1 !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.rounded-4px {
  border-radius: 4px;
}
.rounded-16px {
  border-radius: 16px;
}
.circle {
  border-radius: 50%;
}
.border-top-radius-16px {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.border-bottom-radius-16px {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
/* .border-right-radius-16px {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.border-left-radius-16px {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
} */

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.white-space-nowrap {
  white-space: nowrap;
}
.text-overflow-clip {
  text-overflow: clip;
}
.flex-grow-1 {
  flex-grow: 1;
}
.text-bold {
  font-weight: bold !important;
}

/* Дополнительные классы */
.bg-gradient {
  background-image: var(--gradient) !important;
}
.bg-gradient-2 {
  background-image: var(--gradient-2);
}
.bg-success {
  background-color: var(--success-color);
}
/* .bg-disabled {
  background-color: var(--disabled-color);
} */
.text-color {
  color: var(--text-color);
}
.text-disabled {
  color: var(--disabled-color);
}
.text-error {
  color: var(--error-color);
}
.text-warning {
  color: var(--warning-color);
}
.screen-max-width {
  max-width: var(--screen-max-width);
  margin-left: auto;
  margin-right: auto;
}
.text-break {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fast-fade-enter-active,
.fast-fade-leave-active {
  transition: opacity 100ms ease;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fast-fade-enter-from,
.fast-fade-leave-to {
  opacity: 0;
}
</style>
