import type { Component } from 'vue';
import {
  Notify,
  Loading,
} from 'quasar';
import type { QLoadingShowOptions } from 'quasar'; 

import { efunction } from '@/utils/empties';
import type { ViewName } from '@/constants';


function showError(error: Error) {
  Notify.create({
    timeout: 5,
    message: 'Ошибка при загрузке страницы',
    position: 'top',
  });
  console.error('Error while component loading:', error);
}

const loadingOptions: QLoadingShowOptions
  = Object.freeze({ message: 'Загрузка страницы' });
export function loadView(name: ViewName): () => Promise<Component> {
  return () => {
    const hideLoading = Loading.show
      ? Loading.show(loadingOptions)
      : efunction;

    const result: Promise<Component> = (
      import(`@/views/${name}/index.vue`)
        .catch(showError)
        .finally(hideLoading)
    );
    return result;
  };
}
