import { getCurrentInstance } from 'vue';

import { efunction } from '@/utils/empties'; 


export type OneTimeRef<Type> = {
  value: Type,
  set(value: Type): void,
};

export function useOneTimeRef<Type>(initialValue: Type): OneTimeRef<Type> {
  // const currentInstance = getCurrentInstance();
  const forceUpdate = getCurrentInstance()?.proxy?.$forceUpdate;
  if (!forceUpdate) {
    throw new TypeError('currentInstance is null');
  }

  const oneTimeRef = {
    value: initialValue,
    set(newValue: Type) {
      oneTimeRef.value = newValue;
      forceUpdate();
      oneTimeRef.set = efunction;
    },
  };

  return oneTimeRef;
}
