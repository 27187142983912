import '@/components/icons/gradient.scss';


function createSVGGradient(
  id: string,
  colorsCount: number,
) {
  const stop: JSX.Element[] = [];
  const offsetDifferent = 1 / (colorsCount - 1);
  for (let index = 0; index < colorsCount; index++) {
    stop.push((
      <stop
        stop-color={`var(--${id}-color-${index + 1})`}
        offset={offsetDifferent * index}
      />
    ));
  }

  return (
    <linearGradient
      id={id}
      x1="0%"
      y1="0%"
      x2="100%"
      y2="0%"
    >
      {stop}
    </linearGradient>
  );
}

/* <linearGradient
  id="gradient"
  x1="0%"
  y1="0%"
  x2="100%"
  y2="0%"
>
  <stop stop-color="var(--gradient-color-1)" />
  <stop
    offset="0.5"
    stop-color="var(--gradient-color-2)"
  />
  <stop
    offset="1"
    stop-color="var(--gradient-color-3)"
  />
</linearGradient>

<linearGradient
  id="gradient-dark-1"
  x1="0%"
  y1="0%"
  x2="100%"
  y2="0%"
>
  <stop stop-color="var(--gradient-dark-1-color-1)" />
  <stop
    offset="0.5"
    stop-color="var(--gradient-dark-1-color-2)"
  />
  <stop
    offset="1"
    stop-color="var(--gradient-dark-1-color-3)"
  />
</linearGradient>

<linearGradient
  id="gradient-dark-2"
  x1="0%"
  y1="0%"
  x2="100%"
  y2="0%"
>
  <stop stop-color="var(--gradient-dark-2-color-1)" />
  <stop
    offset="0.5"
    stop-color="var(--gradient-dark-2-color-2)"
  />
  <stop
    offset="1"
    stop-color="var(--gradient-dark-2-color-3)"
  />
</linearGradient> */


export const SVGGradient = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="absolute"
    width="0"
    height="0"
  >
    <defs>
      {createSVGGradient('gradient', 3)}
      {createSVGGradient('gradient-dark-1', 3)}
      {createSVGGradient('gradient-dark-2', 3)}

      {createSVGGradient('gradient-2', 2)}
    </defs>
  </svg>
);
