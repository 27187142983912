import {
  createRouter,
  createWebHistory,
} from 'vue-router';
import type {
  RouteRecordRaw,
  RouteMeta,
} from 'vue-router';

import { loadView } from '@/router/loadView';
import { ViewName } from '@/constants';
import { useAuthStore } from '@/stores/authStore';
import { removeHashBeforeStart } from '@/router/removeHashBeforeStart';
import { handleRouteChange } from '@/plugins/useDialogInQuery';


declare module 'vue-router' {
  interface RouteMeta {
    title: string;
    noAuthorization?: true;
  }
}

function createRoute(
  path: RouteRecordRaw['path'],
  viewName: ViewName,
  meta: RouteMeta,
  beforeEnter?: RouteRecordRaw['beforeEnter'],
): RouteRecordRaw {
  return {
    path,
    meta,
    name: viewName,
    component: loadView(viewName),
    beforeEnter,
  };
}

removeHashBeforeStart();
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    createRoute(
      '/login',
      ViewName.Login,
      {
        title: 'Вход',
        noAuthorization: true,
      },
    ),

    createRoute(
      '/',
      ViewName.Main,
      { title: 'Главная' },
    ),
    createRoute(
      '/promo',
      ViewName.Promo,
      { title: 'Акции' },
    ),

    {
      path: '/.*',
      redirect() {
        if (useAuthStore().isAuthorized) {
          return { name: ViewName.Main };
        }
        return { name: ViewName.Login };
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return false;
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return {
      left: 0,
      top: 0,
    };
  },
});


router.beforeEach((to, from) => {
  const authStore = useAuthStore();

  if ((to.fullPath === from.fullPath) && from.name) {
    return false;
  }
  
  if (!authStore.isAuthorized && !to.meta.noAuthorization) {
    authStore.logOut();
    return { name: ViewName.Login };
  }
  
  if (authStore.isAuthorized && to.meta.noAuthorization) {
    return { name: ViewName.Main };
  }
});

router.afterEach((to) => {
  requestAnimationFrame(() => {
    document.title = to.meta.title || 'ProfiCar';
  });
});
router.afterEach(handleRouteChange);

