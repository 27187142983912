import { defineStore } from 'pinia';

import { http } from '@/plugins/axios';
import type { UsersAuthByOtpPostResult } from '@/api/users';
import { useBotChatStore } from '@/stores/botChat';
import { nextTick } from 'vue';


// Слово "token" в "base64"
const tokenNameInLocalStorage = 'dG9rZW4=';
// Слово "id" в "base64"
const idNameInLocalStorage = 'aWQ=';


function parseId(id: string | null): number | null {
  if (!id) {
    return null;
  }
  try {
    const result = Number(id);
    if (Number.isFinite(result) && Number.isInteger(result) && result > 0) {
      return result;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    // if (import.meta.env.DEV && import.meta.env.VITE_AUTOLOGIN) {
    //   localStorage.setItem(
    //     tokenNameInLocalStorage,
    //     'pshVsCUNHcYwLHaP3enYKwppwSxRE1NZ',
    //   );
    //   localStorage.setItem(idNameInLocalStorage, '6');
    // }

    const accessToken = localStorage.getItem(tokenNameInLocalStorage) || null;
    const userId = parseId(localStorage.getItem(idNameInLocalStorage));
    if (!accessToken || !userId) {
      return {
        accessToken: null,
        userId: null,
      };
    }

    return  {
      accessToken,
      userId,
    };
  },
  getters: {
    isAuthorized(): boolean {
      return Boolean(this.accessToken && this.userId);
    },
  },
  actions: {
    logIn(data: UsersAuthByOtpPostResult) {
      localStorage.setItem(tokenNameInLocalStorage, data.access_token);
      localStorage.setItem(idNameInLocalStorage, String(data.user_id));

      http.setAuthorization(data.access_token);
      this.accessToken = data.access_token;
      this.userId = data.user_id;
    },
    logOut() {
      localStorage.removeItem(tokenNameInLocalStorage);
      localStorage.removeItem(idNameInLocalStorage);

      http.removeAuthorization();
      this.accessToken = null;
      this.userId = null;

      // Для связи с "Flutter"
      console.log('accountExit');

      nextTick(useBotChatStore().onLogout);
    },
  },
});
