import type { AxiosRequestConfig } from 'axios';

import { http } from '@/plugins/axios';
import type { OrdersCartItem } from '@/api/ordersCart';


export type Recomendation = {
  brand: string,
  code: string,
  name: string,
  images: string[],
  avg_price: string,
  rating_data: {
    avg_rating: number,
    reviews_count: number,
  },
};
export type ProductsRecommendationsPostData = OrdersCartItem['offer']['code'][];
export type ProductsRecommendationsPostResult = Recomendation[];

export function productsRecommendationsPost(
  data: ProductsRecommendationsPostData,
  config?: AxiosRequestConfig<ProductsRecommendationsPostData>,
) {
  return http.post<ProductsRecommendationsPostResult>(
    '/products/recommendations/',
    { articles: data },
    config,
  );
}
