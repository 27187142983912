import type { AxiosRequestConfig } from 'axios';

import { http } from '@/plugins/axios';


export type UsersSendOtpPostData = {
  phone: string,
  action: 'auth' | 'update_profile',
};

export const usersSendOtpUrl = '/users/send-otp/';
export function usersSendOtpPost(
  data: UsersSendOtpPostData,
  config?: AxiosRequestConfig<UsersSendOtpPostData>,
) {
  return http.post(
    usersSendOtpUrl,
    data,
    config,
  );
}


export type UsersAuthByOtpPostData = {
  phone: UsersSendOtpPostData['phone'],
  // 6 digits
  otp: number,
};
export type UsersAuthByOtpPostResult = {
  access_token: string,
  user_id: number,
};
export const usersAuthByOtpUrl = '/users/auth-by-otp/';
export function usersAuthByOtpPost(
  data: UsersAuthByOtpPostData,
  config?: AxiosRequestConfig<UsersAuthByOtpPostData>,
) {
  return http.post<UsersAuthByOtpPostResult>(
    '/users/auth-by-otp/',
    data,
    config,
  );
}

export type UsersRegisterByOtpPostData = (
  UsersAuthByOtpPostData
  & { full_name: string, city_id: number}
);
export type UsersRegisterByOtpPostResult = UsersAuthByOtpPostResult;
export const usersRegisterByOtpUrl = '/users/register-by-otp/';
export function usersRegisterByOtpPost(
  data: UsersRegisterByOtpPostData,
  config?: AxiosRequestConfig<UsersRegisterByOtpPostData>,
) {
  return http.post<UsersRegisterByOtpPostResult>(
    '/users/register-by-otp/',
    data,
    config,
  );
}
