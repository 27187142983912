import axios from 'axios';
import type {
  AxiosInstance,
  isCancel as isAxiosCancel,
  // ParamsSerializerOptions,
  isAxiosError as isAxiosError,
} from 'axios';
// import qs from 'qs';
import { Notify } from 'quasar';

// import router from '@/router/index';
// import { autoTrimInterseption } from '@/plugins/axios/autoTrim';
// import store from '@/store';
import { eAbortController } from '@/utils/empties';
import { useAuthStore } from '@/stores/authStore';
import { ViewName } from '@/constants';
import { router } from '@/router';
import {
  usersAuthByOtpUrl,
  usersRegisterByOtpUrl,
  usersSendOtpUrl,
} from '@/api/users';


// Примеси
function logError(error: unknown) {
  if (!isCancel(error)) {
    console.log(error);
  }
  return Promise.reject(error);
}

const ignoredUrls = Object.freeze([
  usersAuthByOtpUrl,
  usersRegisterByOtpUrl,
  usersSendOtpUrl,
]);
// function isIgnoredUrl(url: string) {
//   if (url.startsWith('http')) {
//     return ignoredUrls.includes(url);
//   }

//   for () {
    
//   }
// }

function nullErrorIfCancelOrUnauthorized(error: unknown) {
  // Если это отмена запроса, то ошибка пустая
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  // Если это ошибка авторизации, то ошибка пустая
  if (axios.isAxiosError(error)
    && (
      error.response?.status === 401
      || error.response?.status === 403
    )
    && (
      error.config?.url
      && !ignoredUrls.includes(error.config.url)
    )
  ) {
    Notify.create
    && Notify.create({
      group: 'unauthorized',
      position: 'top',
      timeout: 5000,
      type: 'negative',
      message: 'Учётные данные не были предоставлены',
    });
    useAuthStore().logOut();
    window.location.reload();
    localStorage.clear();
    router.push({ name: ViewName.Login });
    return Promise.reject();
  }

  return Promise.reject(error);
}


// Дополнительные поля и функции
function isCancel(error: unknown): boolean {
  return (
    !error
    || (error as Error).name === 'AbortError'
    || axios.isCancel(error)
  );
}

function parseError(
  error: unknown,
  premessage?: string,
) {
  let message = 'программная ошибка, пожалуйста, обратитесь в поддержку';
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status < 500) {
      try {
        message = parseErrorData(error.response.data);
      } catch (parsingError) {
        console.error(
          'Ошибка при обработке ошибки от сервера:',
          parsingError,
        );
        // Неверный формат ответа от сервера
        console.error(
          'Неверный формат ответ от сервера:',
          error.response.data,
        );
        message = parseServerError();
      }
    } else {
      message = parseServerError(error.response?.status);
    }
  }

  return premessage
    ? (premessage + ': ' + message)
    : message;
}

interface ErrorData {
  message: string;
  code: string;
}
function getErrorMessageFromArray(errors: ErrorData[]) {
  const result: string[] = [];
  for (const error of errors) {
    if (error && error.message) {
      result.push(error.message);
    }
  }
  return result.join(', ');
}
type MultipleErrorsData = {
  [errorName: string]: ErrorData[];
};
function parseErrorData(data: unknown): string {
  if (data && typeof data === 'object') {
    if (Array.isArray(data)) {
      return getErrorMessageFromArray(data);
    }

    if ('detail' in data) {
      return String((data as { detail: unknown }).detail);
    }

    const result: string[] = [];
    for (const field in data) {
      const errors = (data as MultipleErrorsData)[field];
      if (!Array.isArray(errors)) {
        continue;
      }
      result.push(getErrorMessageFromArray(errors));
    }
    return result.join('\n');
  }

  return (data as null) ?? '';
}

function parseServerError(status?: string | number): string {
  status = status ? (` ${status}`) : '';
  return `серверная ошибка${status}. Ведутся технические работы`;
}

function showErrorMessage(
  error: Parameters<typeof parseError>['0'],
  premessage: Parameters<typeof parseError>['1'],
) {
  Notify.create
  && Notify.create({
    position: 'top',
    timeout: 5000,
    type: 'negative',
    message: parseError(error, premessage),
  });
}


export interface AxiosInstanceExtended extends AxiosInstance {
  isAxiosCancel: typeof isAxiosCancel;
  isAxiosError: typeof isAxiosError;
  isCancel: typeof isCancel;
  parseError: typeof parseError;
  showErrorMessage: typeof showErrorMessage;
  eAbortController: typeof eAbortController;
  setAuthorization: (accessToken: string) => void;
  removeAuthorization: () => void;
  // isAuthorized(): boolean;
}

export function createAxiosInstance(
  params: Parameters<typeof axios['create']>['0'],
): AxiosInstanceExtended {
  const result = axios.create(params) as AxiosInstanceExtended;

  // Для правильного срабатывания HMR
  if (import.meta.env.DEV) {
    result.interceptors.request.use((request) => {
      const authStore = useAuthStore();
      if (authStore.isAuthorized && authStore.accessToken) {
        http.setAuthorization(authStore.accessToken);
        request.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
      }
      return request;
    });
  }

  result.interceptors.response.use(undefined, logError);
  // autoTrimInterseption,
  result.interceptors.response.use(undefined, nullErrorIfCancelOrUnauthorized);

  result.isAxiosCancel = axios.isCancel;
  result.isAxiosError = axios.isAxiosError;
  result.isCancel = isCancel;
  result.parseError = parseError;
  result.showErrorMessage = showErrorMessage;
  result.eAbortController = eAbortController;

  result.setAuthorization = (accessToken: string) => {
    result.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  };

  result.removeAuthorization = () => {
    delete result.defaults.headers.common['Authorization'];
  };

  // result.isAuthorized = () => {
  //   return Boolean(result.defaults.headers.common['Authorization']);
  // };

  return result;
}


// const qsOptions = Object.freeze({ arrayFormat: 'comma' });
export const http = createAxiosInstance({
  // baseURL: String(new URL('api', import.meta.env.VITE_APP_BASE_URL)),

  baseURL: `https://app.proficar.su/api`,
  timeout: 300000,
  // paramsSerializer: {
  //   encode(params) {
  //     return qs.stringify(params, qsOptions);
  //   },
  // },
});
