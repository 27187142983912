<script lang="ts">
import {
  defineComponent,
  shallowRef,
  watch,
} from 'vue';
import {
  QDialog,
  QBtn,
} from 'quasar';


import { useDialogInQuery } from '@/plugins/useDialogInQuery';
import { useSecondsTimer } from '@/utils/useSecondsTimer';


export interface Props {
  onOk: Function,
  text: string,
  delay?: number,
}

export default defineComponent({
  name: 'AcceptDialog',
  components: {
    QDialog,
    QBtn,
  },
  props: {
    onHide: {
      type: Function,
      required: true,
    },
    onOk: {
      type: Function,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    delay: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const visible = shallowRef(Boolean(props.onOk && props.text));
    function hide() {
      visible.value = false;
    }
    useDialogInQuery(
      () => visible.value,
      (value) => { visible.value = value; },
      'accept',
    );

    const okDisabled = shallowRef(false);
    function onTimerEnd() {
      secondsTimer.value = undefined;
      okDisabled.value = false;
    }
    const secondsTimer = shallowRef<
      undefined |
      ReturnType<typeof useSecondsTimer>
    >();
    if (props.delay) {
      okDisabled.value = true;
      secondsTimer.value = useSecondsTimer(props.delay, onTimerEnd);
    }

    watch(
      props,
      () => {
        if (props.onOk && props.text) {
          visible.value = true;

          secondsTimer.value?.stopTimer();
          secondsTimer.value = undefined;
          okDisabled.value = false;
          if (props.delay) {
            okDisabled.value = true;
            secondsTimer.value = useSecondsTimer(props.delay, onTimerEnd);
          }
        }
      },
    );
    watch(
      visible,
      () => {
        if (!visible.value) {
          props.onHide();
          secondsTimer.value?.stopTimer();
          secondsTimer.value = undefined;
          okDisabled.value = false;
        }
      },
    );

    function onClickOk() {
      if (!props.onOk) {
        return;
      }
      props.onOk();
      hide();
    }

    return {
      visible,
      hide,
      onClickOk,
      secondsTimer,
      okDisabled,
    };
  },
});
</script>


<template>
<QDialog
  position="bottom"
  no-route-dismiss
  v-model="visible"
>
  <div class="w-600px">
    <div class="text-center py-14px bg-white font-size-14px text-bold border-top-radius-16px border-bottom-gradient">
      {{ text }}
    </div>
    <div>
      <QBtn
        class="full-width border-bottom-radius-16px bg-white font-size-16px text-black"
        square
        :label="(
          secondsTimer
            ? `Да (${secondsTimer.timer.value})`
            : 'Да'
        )"
        :disable="okDisabled"
        @click="onClickOk"
      />
    </div>
    
    <QBtn
      class="full-width mt-4px white-card"
      @click="hide"
    >
      <span class="text-bold text-color">
        Отмена
      </span>
    </QBtn>
  </div>
</QDialog>
</template>
