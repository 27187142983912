import {
  shallowReactive,
  shallowRef,
  watch,
} from 'vue';
import { defineStore } from 'pinia';
import {
  EventBus,
  // is,
  // debounce,
} from 'quasar';

import { http } from '@/plugins/axios';
import {
  ordersCartGet,
  ordersCartCleanCartGet,
} from '@/api/ordersCart';
import type {
  OrdersCartGetResult,
  OrdersCartItem,
} from '@/api/ordersCart';
import { earray } from '@/utils/empties';
import { createCartItemProcessData } from '@/stores/cartStore/CartItemProcess';
// eslint-disable-next-line max-len
import type { CartItemProcessContext } from '@/stores/cartStore/CartItemProcess';
import type { Offer } from '@/api/chatbotMessages';
import { ONE_DAY } from '@/constants';
import { productsRecommendationsPost } from '@/api/productsRecommendations';
// eslint-disable-next-line max-len
import type { ProductsRecommendationsPostResult } from '@/api/productsRecommendations';
// import { eset } from '@/utils/empties';


const EMPTY_CART = Object.freeze({
  active_count: 0,
  active_price: 0,
  all_count: 0,
  all_price: 0,
  order_items: earray,
}) as OrdersCartGetResult;

const deliveryDateFormatter = new Intl.DateTimeFormat(
  'ru',
  {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
  },
);
export type CleanCartEventBusEvents = { cleanCart: () => void };

export const useCartStore = defineStore('cart', () => {
  const getCartAbortController: CartItemProcessContext['getCartAbortController']
    = { value: http.eAbortController };
  const cartLoading = shallowRef(true);
  function setCartLoadingTrue() {
    cartLoading.value = true;
  }
  const firstLoading = shallowRef(true);


  // Корзина товаров
  const cart = shallowRef(EMPTY_CART);
  const cartItemsIndex = new Map<Offer['uuid'], OrdersCartItem>();
  /*
    Используется для отображения данных в интерфейсе,
    пока идут запросы на сервер.
    Чтобы можно было добавлять несколько товаров или их количество,
    не дожидаясь загрузки на сервер.
    Количество и товары там изменяются, а запросы на сервер уходят с задержкой.
  */
  const cartProcessData: CartItemProcessContext['cartProcessData']
    = shallowReactive(new Map());

  // Получить корзину
  function getCart() {
    getCartAbortController.value.abort();
    getCartAbortController.value = new AbortController();
    cartLoading.value = true;
    ordersCartGet({ signal: getCartAbortController.value.signal })
      .then((response) => {
        if (response.config.signal !== getCartAbortController.value.signal) {
          return;
        }
        setCartData(response.data);
        cartLoading.value = false;
        firstLoading.value = false;
      })
      .catch((error) => {
        if (http.isCancel(error)) {
          return;
        }
        http.showErrorMessage(error, 'Не удалось получить данные корзины');
        cartLoading.value = false;
        firstLoading.value = false;
      });
  }

  const cartItemProcessContext: CartItemProcessContext = {
    cartProcessData,
    getCartAbortController,
    setCartLoadingTrue,
    getCart,
  };

  function setCartData(
    data: Awaited<ReturnType<typeof ordersCartGet>>['data'],
  ) {
    cart.value = data;
    cartItemsIndex.clear();
    deliveryDate = undefined;

    for (const cartItem of data.order_items) {
      const cartItemProcessData = cartProcessData.get(cartItem.offer.uuid);
      cartItemsIndex.set(cartItem.offer.uuid, cartItem);
      // Если товар есть в интерфейсе, то синхронизировать его
      if (cartItemProcessData) {
        cartItemProcessData.sync(cartItem);
        continue;
      }
      // Иначе добавить в корзину
      cartProcessData.set(
        cartItem.offer.uuid,
        createCartItemProcessData(
          cartItem,
          cartItemProcessContext,
          cartItem.id,
        ),
      );
    }
  }


  // Добавить товар в корзину
  function putItemInCart(
    offer: OrdersCartItem['offer'],
    count: OrdersCartItem['count'],
  ) {
    // Обновление количества
    const cartItemProcess = cartProcessData.get(offer.uuid);
    if (cartItemProcess) {
      cartItemProcess.updateCount(count);
      return;
    }

    // Добавление нового товара
    cartProcessData.set(
      offer.uuid,
      createCartItemProcessData(
        {
          offer,
          count,
        },
        cartItemProcessContext,
      ),
    );
  }


  const cleanCartEventBus = new EventBus<{ cleanCart: () => void }>();
  const cleanCartLoading = shallowRef(false);
  function cleanCart() {
    if (!cart.value.order_items.length || cleanCartLoading.value) {
      return;
    }
    getCartAbortController.value.abort();
    getCartAbortController.value = http.eAbortController;

    for (const cartItemProcess of cartProcessData.values()) {
      cartItemProcess.cancelUpdateRequest();
      cartItemProcess.cancelDeleteRequest();
    }
    cartProcessData.clear();

    cleanCartLoading.value = true;
    ordersCartCleanCartGet()
      .then(() => {
        setCartData(EMPTY_CART);
        cleanCartEventBus.emit('cleanCart');
      })
      .catch((error) => {
        if (http.isCancel(error)) {
          return;
        }
        http.showErrorMessage(error, 'Не удалось очистить корзину');
      })
      .finally(() => {
        cleanCartLoading.value = false;
      });
  }
  function cleanCartWithoutRequest() {
    cartProcessData.clear();
    setCartData(EMPTY_CART);
    cleanCartEventBus.emit('cleanCart');
  }


  function sendToFlutterCartProcessDataSize() {
    console.log(`shopsItem: ${cartProcessData.size}`);
    if (window.isFlutterInAppWebViewReady) {
      window.flutter_inappwebview?.callHandler?.(
        'shopItems',
        cartProcessData.size,
      );
    }
  }
  watch(
    () => cartProcessData.size,
    sendToFlutterCartProcessDataSize,
  );

  function getCartItemPrice(offerUuid: Offer['uuid']) {
    const cartItemPrice = cartItemsIndex.get(offerUuid);
    if (cartItemPrice) {
      return cartItemPrice.total_price;
    }
    
    return 'Неизвестно';
    // const cartItemProccess = cartProcessData.get(offerUuid);
    // if (!cartItemProccess) {
    //   return 'Неизвестно';
    // }

    // return (
    //   cartItemProccess.count.value
    //   * Number(cartItemProccess.offer.value.price)
    // );
  }
  function getCartItemCashback(offerUuid: Offer['uuid']) {
    const cartItemPrice = cartItemsIndex.get(offerUuid);
    if (cartItemPrice) {
      return cartItemPrice.cashback;
    }
    
    return 'Неизвестно';
  }


  let deliveryDate: undefined | string;
  function getDeliveryDate() {
    if (!deliveryDate) {
      let maxDays = 0;
      for (const cartItem of cart.value.order_items) {
        if (cartItem.offer.delivery_days > maxDays) {
          maxDays = cartItem.offer.delivery_days;
        }
      }

      deliveryDate = deliveryDateFormatter.format(
        Math.trunc(Date.now() + ONE_DAY * maxDays),
      );
    }
    return deliveryDate;
  }

  
  const loadingRecommendations = shallowRef(false);
  // let lastRecommendationsCodes = eset;
  let getRecommendationsAbortController = http.eAbortController;
  // eslint-disable-next-line max-len
  const recommendations = shallowRef(earray as ProductsRecommendationsPostResult);
  function getRecommendations() {

    const codes = new Set<OrdersCartItem['offer']['code']>();
    for (const cartItemProcess of cartProcessData.values()) {
      codes.add(cartItemProcess.offer.value.code);
    }
    // /**
    //  * Если происходит изменение количества какого-то товара,
    //  * а не изменения списка товаров и при этом идёт загрузка
    //  */
    // if (
    //   loadingRecommendations.value
    //   && is.deepEqual(codes, lastRecommendationsCodes)
    // ) {
    //   return;
    // }

    getRecommendationsAbortController.abort();
    if (!codes.size) {
      return;
    }
    loadingRecommendations.value = true;
    const getRecommendationsAbortController_ = new AbortController();
    getRecommendationsAbortController = getRecommendationsAbortController_;

    productsRecommendationsPost(
      Array.from(codes),
      { signal: getRecommendationsAbortController_.signal },
    )
      .then((response) => {
        // eslint-disable-next-line max-len
        if (getRecommendationsAbortController_ !== getRecommendationsAbortController) {
          return;
        }
        // lastRecommendationsCodes = codes;
        recommendations.value = response.data;
        loadingRecommendations.value = false;
      })
      .catch((error) => {
        if (http.isCancel(error)) {
          return;
        }
        http.showErrorMessage(error, 'Не удалось получить рекомендации');
        loadingRecommendations.value = false;
      });
  }
  // const getRecommendationsDebounced = debounce(getRecommendations, 500);

  return {
    firstLoading,
    cartLoading,
    setCartLoadingTrue,
    cart,

    cartProcessData,
    getCart,
    putItemInCart,

    cleanCartWithoutRequest,
    cleanCart,
    cleanCartLoading,
    cleanCartEventBus,

    sendToFlutterCartProcessDataSize,

    getCartItemPrice,
    getCartItemCashback,

    getDeliveryDate,

    recommendations,
    getRecommendations,
    // getRecommendationsDebounced,
  };
});
